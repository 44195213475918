import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// --------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">



<path d="M5906 8662 c-2 -4 13 -6 35 -4 21 2 39 6 39 8 0 7 -69 4 -74 -4z"/>
<path d="M5698 8639 c-227 -36 -415 -121 -550 -247 l-50 -46 -103 3 c-97 2
-258 -18 -293 -38 -10 -6 21 -27 99 -68 150 -79 304 -172 409 -247 106 -76
274 -238 339 -325 71 -96 142 -221 126 -221 -3 0 -10 11 -15 25 -9 24 -13 25
-80 22 -46 -2 -70 1 -70 9 0 6 -6 11 -12 11 -7 0 -22 4 -33 9 -13 5 -4 -11 26
-46 171 -201 271 -457 311 -795 l13 -110 3 273 c3 295 6 309 49 222 26 -51 57
-169 75 -282 7 -43 15 -78 19 -78 22 0 79 308 79 425 0 201 -73 474 -173 647
-37 65 -40 77 -40 137 3 132 36 214 94 226 17 4 28 9 25 12 -3 3 -19 1 -35 -5
-40 -14 -45 -5 -47 81 -1 37 -3 69 -4 72 -1 3 -4 32 -5 65 -1 33 -4 62 -5 65
-1 3 -3 13 -4 23 -1 9 -5 22 -9 29 -4 6 -3 15 3 18 8 5 7 11 -2 22 -6 8 -12
35 -13 60 0 36 3 47 20 54 46 18 -26 18 -147 -2z"/>
<path d="M7296 8418 c-10 -6 -44 -21 -75 -33 -31 -12 -97 -41 -148 -64 l-91
-43 18 -32 c46 -78 94 -287 101 -438 2 -27 4 -48 5 -48 1 0 35 11 77 25 42 13
103 29 138 34 34 6 64 12 66 15 2 2 -15 7 -39 11 -24 5 -37 10 -30 12 6 3 12
9 12 14 0 6 -4 8 -9 4 -6 -3 -8 15 -7 42 2 27 -1 54 -7 60 -5 7 -7 19 -4 28 4
8 1 17 -5 21 -7 4 -6 9 3 15 11 6 11 11 1 21 -6 6 -12 32 -12 56 0 24 -4 41
-8 38 -5 -3 -8 17 -7 45 1 27 -1 52 -5 54 -3 2 -5 25 -4 52 1 26 -3 53 -8 60
-9 12 -6 14 62 50 14 7 18 13 10 13 -8 0 -24 -6 -34 -12z"/>
<path d="M6141 7861 c-12 -8 -12 -16 3 -55 10 -25 20 -46 22 -46 2 0 4 25 4
55 0 56 -4 62 -29 46z"/>
<path d="M4121 7476 c2 -3 77 -41 167 -85 233 -116 447 -248 575 -354 l47 -40
0 -119 c0 -99 -2 -118 -15 -118 -9 0 -57 25 -108 56 -129 78 -308 164 -624
298 -150 64 -275 116 -278 116 -9 0 -5 -141 6 -235 46 -377 237 -709 517 -899
135 -91 342 -165 471 -167 89 -2 97 -7 135 -84 47 -96 98 -159 181 -222 189
-144 435 -214 765 -217 96 0 183 2 192 5 30 9 32 19 5 19 -15 0 -26 3 -26 8 1
4 0 10 -1 15 -1 4 -4 30 -5 57 -1 28 -4 52 -5 55 -1 3 -3 40 -4 83 -1 42 -5
83 -10 90 -4 8 -10 41 -11 73 -2 33 -5 63 -8 66 -3 3 -2 16 3 29 9 22 8 23
-16 14 -42 -16 -115 -11 -159 10 -74 36 -85 82 -95 385 -6 190 -10 234 -33
320 -89 346 -242 611 -440 763 -58 45 -81 54 -82 32 0 -8 -11 -17 -25 -18 -14
-2 -29 -7 -35 -11 -5 -4 -17 -7 -25 -6 -8 0 -18 -3 -21 -7 -3 -5 -31 -9 -61
-11 -31 -1 -56 -6 -55 -12 1 -5 -8 -10 -20 -11 -13 -1 -25 -3 -28 -4 -3 -1 -8
-3 -12 -5 -33 -9 -44 -18 -31 -27 8 -5 3 -6 -14 -2 l-28 6 0 -65 c0 -36 -4
-68 -9 -71 -8 -5 -266 158 -326 207 -36 30 -70 39 -231 67 -109 18 -234 27
-223 16z"/>
<path d="M4708 7393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4840 7350 c65 -23 60 -22 60 -12 0 10 -68 32 -96 32 -11 -1 6 -9 36
-20z"/>
<path d="M6380 7233 c0 -5 46 -122 102 -261 l103 -253 185 7 c102 3 186 7 187
8 2 1 -25 22 -60 46 -143 100 -457 394 -473 442 -5 17 -44 26 -44 11z"/>
<path d="M7106 6448 c-3 -101 -9 -201 -12 -223 -26 -208 -53 -313 -98 -388
-29 -48 -31 -56 -20 -82 7 -17 33 -79 59 -140 25 -60 56 -135 67 -165 11 -30
22 -57 24 -58 1 -2 78 36 171 84 92 48 194 101 227 117 32 16 72 38 90 48 l31
20 -30 -3 c-29 -3 -30 -2 -28 37 1 22 -2 44 -6 50 -4 5 -7 31 -6 58 1 36 -2
47 -14 47 -14 0 -14 1 -1 10 8 5 10 10 4 10 -14 0 -14 30 0 30 7 0 7 3 1 8
-13 9 -13 12 -21 114 -3 46 -10 88 -14 93 -4 6 -6 26 -5 45 1 19 -3 41 -9 49
-9 12 -8 13 3 6 11 -6 12 -5 5 6 -5 8 -9 34 -10 59 0 25 -4 52 -7 60 -4 8 -9
34 -11 58 -4 43 -4 43 25 37 16 -4 29 -2 29 3 0 5 -14 12 -32 16 -63 12 -343
138 -372 167 -28 27 -33 5 -40 -173z"/>
<path d="M6203 5423 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3420 5191 c-54 -18 -89 -42 -112 -78 -22 -37 -23 -44 -23 -258 0
-197 2 -224 19 -255 36 -65 91 -92 196 -94 63 -1 84 3 124 24 68 35 85 62 95
150 4 41 6 81 3 88 -3 8 -30 12 -88 12 l-84 0 0 -78 c0 -83 -9 -102 -46 -102
-44 0 -48 20 -49 223 0 106 2 210 5 232 5 39 6 40 45 40 l40 0 3 -92 3 -93 89
0 88 0 -4 63 c-9 136 -21 165 -86 198 -45 23 -173 35 -218 20z"/>
<path d="M4047 5063 c-17 -120 -35 -241 -62 -417 -8 -55 -15 -105 -15 -113 0
-11 18 -13 88 -11 l87 3 3 35 c6 76 8 80 57 80 l44 0 7 -60 7 -60 83 0 c77 0
84 2 84 19 0 24 -86 547 -95 584 -2 4 -3 18 -4 32 l-1 25 -133 0 -134 0 -16
-117z m182 -142 c6 -57 11 -121 11 -143 l0 -39 -42 3 -43 3 3 45 c1 25 10 94
19 153 20 137 37 130 52 -22z"/>
<path d="M4680 4850 l0 -330 75 0 75 0 0 220 c0 177 3 220 13 220 15 0 24 -44
76 -390 l6 -45 51 -3 51 -3 7 43 c3 24 13 93 22 153 45 324 58 330 62 28 l2
-223 75 0 75 0 0 330 0 330 -119 0 -120 0 -6 -57 c-10 -85 -36 -247 -40 -251
-13 -13 -25 19 -36 95 -7 49 -13 90 -14 93 -1 3 -5 31 -9 63 l-8 57 -119 0
-119 0 0 -330z"/>
<path d="M5560 4851 l0 -331 85 0 85 0 0 150 0 150 35 0 c48 0 55 -22 55 -176
l0 -124 81 0 81 0 -4 134 c-3 124 -5 137 -27 160 -12 13 -34 27 -47 30 -32 8
-32 36 1 36 36 0 61 30 69 83 13 83 -12 162 -61 194 -22 15 -55 19 -190 22
l-163 3 0 -331z m251 208 c15 -29 7 -128 -12 -140 -22 -14 -56 -10 -63 7 -3 9
-6 47 -6 85 l0 69 35 0 c25 0 37 -6 46 -21z"/>
<path d="M6233 5168 c2 -7 28 -87 56 -178 29 -91 57 -178 62 -194 5 -16 9 -85
9 -152 l0 -124 80 0 80 0 0 118 0 117 69 210 68 210 -70 3 c-39 2 -74 -1 -78
-5 -4 -4 -18 -60 -30 -123 -25 -131 -44 -166 -54 -104 -11 68 -36 198 -41 217
-5 14 -18 17 -81 17 -56 0 -74 -3 -70 -12z"/>
<path d="M7270 4850 l0 -332 183 4 c206 3 229 11 244 78 4 19 8 134 8 255 0
245 -5 264 -64 299 -30 17 -58 20 -203 24 l-168 5 0 -333z m257 203 c9 -37 9
-348 -1 -395 -6 -35 -10 -38 -41 -38 l-35 0 0 230 0 230 35 0 c29 0 36 -4 42
-27z"/>
<path d="M8047 5158 c-3 -13 -8 -45 -12 -73 -3 -27 -11 -72 -16 -100 -5 -27
-20 -133 -35 -235 -14 -102 -28 -195 -31 -207 -5 -23 -3 -23 85 -23 l90 0 7
61 7 60 47 -3 c45 -3 46 -4 49 -38 6 -82 3 -80 97 -80 47 0 85 2 85 4 0 9 -91
556 -101 604 l-10 52 -129 0 c-124 0 -128 -1 -133 -22z m163 -220 c7 -62 13
-131 14 -153 1 -39 0 -40 -37 -43 -27 -2 -39 1 -43 13 -6 15 20 249 31 283 12
34 23 1 35 -100z"/>
<path d="M8580 5120 l0 -60 60 0 60 0 0 -270 0 -270 85 0 85 0 0 270 0 270 60
0 60 0 0 60 0 60 -205 0 -205 0 0 -60z"/>
<path d="M9266 5153 c-3 -16 -10 -57 -15 -93 -5 -36 -23 -162 -40 -280 -17
-118 -33 -225 -36 -237 -5 -23 -4 -23 84 -23 l90 0 6 48 c10 73 10 73 57 70
41 -3 43 -4 46 -38 6 -82 3 -80 98 -80 l87 0 -8 43 c-3 23 -13 80 -20 127 -7
47 -21 128 -29 180 -9 52 -24 138 -32 190 -9 52 -18 101 -20 108 -2 9 -38 12
-133 12 l-129 0 -6 -27z m162 -195 c6 -51 14 -121 18 -156 l6 -63 -43 3 -44 3
2 55 c2 51 24 221 32 248 1 1 5 2 10 2 5 0 13 -42 19 -92z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
